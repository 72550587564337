import { useEffect, useState } from "react";
import IntakeCensorReading from "../intake-censor-reading/IntakeCensorReading";
import * as S from "./IntakeReadingStyles";
import IntakeNavigator from "./intake-navigator/IntakeNavigator";
import IntakeLoadingSkeleton from "./intake-loading-skeleton/IntakeLoadingSkeleton";
import {
  StyledSectionHeading,
  StyledSectionSubHeading,
} from "../../styled-components/StyledSectionHeading";
import { getSensorHistoricalData, getSensorHistoricalDataByDate } from "../../services/api-service/apiService";
import { Button, Grid, TextField, Typography } from "@mui/material";
import moment from "moment";
import { FileDownload } from "@mui/icons-material";
import { useAppDispatch } from "../../store/hooks";
import { showToast, TOAST_TYPES } from "../../store/slices/toastSlice";

const IntakeReading = (props: { intakeDevices: any, readingParams: any }) => {
  const dispatch = useAppDispatch();
  const [currentIntake, setCurrentIntake] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [intakeData, setIntakeData] = useState([]);
  const intake = props?.intakeDevices?.find((item: any) => item.name === 'Intake');
  const devicesWithIds = intake?.devices?.map((device: any, index: number) => ({
    ...device,
    id: index + 1
  }));

  const handleIntakeChange = (intake: number) => {
    setCurrentIntake(intake);
  };

  const getIntakeDetails = async (intake: number) => {
    setIsLoading(true);
    const data = devicesWithIds?.find((item: any) => item.id === intake);
    if (data?.deviceId) {
      const res = await getSensorHistoricalData(data.deviceId);
      setIntakeData(res.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getIntakeDetails(currentIntake).then(r => { });
    // eslint-disable-next-line
  }, [currentIntake, props.intakeDevices]);

  const [fromDate, setFromDate] = useState<string>(moment().startOf('month').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState<string>(moment().endOf('month').format('YYYY-MM-DD'));
  const [isScheduleEqual, setIsScheduleEqual] = useState<boolean>(false);
  const isEndDateValid = moment(toDate).isSameOrAfter(moment(fromDate), 'day');

  useEffect(() => {
    if (fromDate && toDate) {
      const isSame = moment(fromDate).isSame(moment(toDate), 'day');
      setIsScheduleEqual(isSame);
    }
  }, [fromDate, toDate]);

  const onExport = async () => {
    if (!isEndDateValid) {
      alert('End date cannot be before start date!');
      return;
    }

    const deviceData = devicesWithIds?.find((item: any) => item?.id === currentIntake);
    
    if (deviceData?.deviceId) {
      const sensorHistory: any = await getSensorHistoricalDataByDate(deviceData.deviceId, fromDate, toDate);
      const records: any[] = sensorHistory?.data?.data;
      if (records?.length) {
        downloadCSV(records, deviceData?.name || '');
      } else {
        dispatch(showToast({
          type: TOAST_TYPES.WARNING,
          message: "No records found for the selected date range!..."
        }))
      }
    }
  };

  const downloadCSV = (records: any[], name: string = '') => {
    const headers = ['DateTime', 'Temperature', 'pH Level', 'Dissolved Oxygen', 'Ammonia Level'];
    const csvRows = [
      headers.join(','),
      ...records.map((record: any) => [
        record.timestamp ? moment(record?.timestamp).format('DD-MM-YYYY hh:mm:ss A') : '',
        record.temp,
        record.ph,
        record.do,
        record.amm
      ].join(',')) // Add record rows
    ].join('\n');

    // Encode CSV data as a URI (data URL)
    const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(csvRows)}`;

    // Create a link and trigger the download
    const link = document.createElement('a');
    link.href = csvData;
    link.download = `SensorRecords-${name}-${moment().format('DDMMYYYYhhmmssA')}.csv`;
    document.body.appendChild(link);
    link.click();

    // Clean up the link after download
    document.body.removeChild(link);
  };

  return (
    <S.IntakeReadingWrapper>
      <S.IntakeCardHeaderStyle>
        <div>
          <StyledSectionHeading>Intake Reading</StyledSectionHeading>
          <StyledSectionSubHeading>Reading Trends</StyledSectionSubHeading>
        </div>
        <IntakeNavigator
          handleIntakeChange={handleIntakeChange}
          selectedIntake={currentIntake}
          intakes={devicesWithIds}
        />
      </S.IntakeCardHeaderStyle>

      <S.StyledDatePickerContainer container>
        <Grid item xl={2} lg={3} md={4} sm={12} xs={12} className="picker-wrap">
          <Typography sx={{fontWeight: 600, fontSize: '1rem', marginBottom: '10px', color: 'rgba(100, 116, 139, 1)'}}>Sensor Data Download</Typography>
          
          <Typography className="date-label">Start Date :</Typography>

          <TextField type="date" fullWidth className="date-field" name="fromDate" size="small"
            autoComplete="off" value={fromDate} onChange={(e) => setFromDate(e.target.value)}
          />
        </Grid>
        <Grid item xl={2} lg={3} md={4} sm={12} xs={12} className="picker-wrap">
          <Typography className="date-label">End Date :</Typography>

          <TextField type="date" fullWidth className="date-field" name="toDate" size="small"
            autoComplete="off" value={toDate} onChange={(e) => setToDate(e.target.value)}
            error={!isEndDateValid}
          // helperText={!isEndDateValid ? "End date can't be before start date" : ""}
          />
        </Grid>
        <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className="picker-wrap">
          <Button className="sc-btn" variant={"contained"} disabled={isScheduleEqual || !isEndDateValid} onClick={() => onExport()}><FileDownload sx={{ fontSize: '1.2rem' }} />Export</Button>
        </Grid>
      </S.StyledDatePickerContainer>


      {isLoading ? <IntakeLoadingSkeleton /> : <IntakeCensorReading data={intakeData} waterQualityParams={props.readingParams} />}
    </S.IntakeReadingWrapper>
  );
};

export default IntakeReading;
