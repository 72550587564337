import { Grid, styled } from "@mui/material";
import { CColorCodeVariables } from "../../constants/color.constants";

export const IntakeReadingWrapper = styled('section')(({ theme }) => ({
  background: theme?.palette?.background?.default,
  padding: '1rem',
  borderRadius: '8px',
  boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.4)`,

  [theme.breakpoints.down('sm')]: {
    // Styles applied for screens equal to or wider than 600px (sm breakpoint)
    padding: '0.4rem',
  },
}))

export const IntakeCardHeaderStyle = styled('header')({
  paddingLeft: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '10px'
});

export const StyledDatePickerContainer = styled(Grid)<{}>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginTop: '1rem',
  '.picker-wrap': {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    justifyContent: 'flex-end',
    marginLeft: '10px',
    '& .date-label': {
      fontWeight: 500
    },
    '& .date-field': {
      backgroundColor: 'white'
    }
  }
}));