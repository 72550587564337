import { Grid, useTheme } from "@mui/material";
import CensorReadingScale from "./censor-reading-scale/CensorReadingScale";
import CensorReadingChart from "./censor-reading-chart/CensorReadingChart";
import {useState} from "react";

const IntakeCensorReading = (props: {data:any, waterQualityParams: any}) => {
  const theme = useTheme();
  const [selectedReading, setSelectedReading] = useState(1);
  const handleSelectedReading = (key: number) => {
    setSelectedReading(key);
  };

  function getThresholdName(value:number, unit:string) {
    if (props.waterQualityParams) {
      // @ts-ignore
      for (const range in props.waterQualityParams[unit]) {
        // @ts-ignore
        if (value >= props.waterQualityParams[unit][range].min && value <= props.waterQualityParams[unit][range].max) {
          return range.split('-')[0].toUpperCase();
        }
      }
    }
  }

  let cardArray = [
    {
      key: 1,
      value: props?.data[props.data.length - 1]?.ph.toFixed(2) ?? '',
      unit: "",
      type: "pH Level",
      readingStatus: "up",
      readingVal: props?.data[props.data.length - 1]?.ph.toFixed(2) ?? '',
      weekVal: "+1.01% this week",
      range: {
        min: 0,
        max: 14
      },
      state: getThresholdName(props?.data[props.data.length - 1]?.ph, 'ph'),
      typeCode: 'ph',
      limits: {
        lowerCrit: 4.9,
        lowerAccept: 5.9,
        optimal: 7,
        upperAccept: 8.1,
        upperCrit: 9.1
      }
    },
    {
      key: 2,
      value: props?.data[props.data.length - 1]?.temp.toFixed(2) ?? '',
      unit: "°C",
      type: "Temperature",
      readingStatus: "up",
      readingVal: props?.data[props.data.length - 1]?.temp.toFixed(2) ?? '',
      weekVal: "+1.01% this week",
      range: {
        min: 0,
        max: 50
      },
      state: getThresholdName(props?.data[props.data.length - 1]?.temp, 'temp'),
      typeCode: 'temp',
      limits: {
        lowerCrit: 19.9,
        lowerAccept: 24.9,
        optimal: 28,
        upperAccept: 31,
        upperCrit: 36
      }
    },
    {
      key: 3,
      value: props?.data[props.data.length - 1]?.do.toFixed(3) ?? '',
      unit: "mg/L",
      type: "Dissolved Oxygen",
      readingStatus: "down",
      readingVal: props?.data[props.data.length - 1]?.do.toFixed(3) ?? '',
      weekVal: "+1.01% this week",
      range: {
        min: 0,
        max: 10
      },
      state: getThresholdName(props?.data[props.data.length - 1]?.do, 'do'),
      typeCode: 'do',
      limits: {
        lowerCrit: 2.49,
        lowerAccept: 4,
        optimal: 4.1,
        upperAccept: 9999,
        upperCrit: 9999
      }
    },
    {
      key: 4,
      value: props?.data[props.data.length - 1]?.amm.toFixed(5) ?? '',
      unit: "mg/L",
      type: "Ammonia Level",
      readingStatus: "down",
      readingVal: props?.data[props.data.length - 1]?.amm.toFixed(5) ?? '',
      weekVal: "+1.01% this week",
      range: {
        min: 0,
        max: 3
      },
      state: getThresholdName(props?.data[props.data.length - 1]?.amm, 'amm'),
      typeCode: 'amm',
      limits: {
        lowerCrit: 1.5,
        lowerAccept: 1,
        optimal: 0.5,
        upperAccept: 9999,
        upperCrit: 9999
      }
    }
  ];


  return (
    <Grid
      container
      sx={{
        [theme.breakpoints.down("sm")]: {
          // Styles applied for screens equal to or wider than 600px (sm breakpoint)
          marginTop: "0.5rem",
        },
      }}
    >
      <Grid item lg={3} xl={3} md={12} sm={12} xs={12}>
        <Grid container>
          {cardArray?.map((item) => (
            <CensorReadingScale
              handleSelectedReading={handleSelectedReading}
              key={item?.key}
              selected={selectedReading === item?.key}
              censorReadings={item}
              timestamp={props?.data[props.data.length - 1]?.timestamp}
              range={item.range}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item lg={9} xl={9} md={12} sm={12} xs={12}>
        <CensorReadingChart data={props.data} typeCode={cardArray[selectedReading-1].typeCode} thresholds={cardArray[selectedReading-1].limits}/>
      </Grid>
    </Grid>
  );
};

export default IntakeCensorReading;
