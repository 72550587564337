import { FC, useEffect, useState } from "react"
import ControlPhysicalDeviceDetails from "./control-pysical-device-details/ControlPhysicalDeviceDetails";
import { Grid } from "@mui/material";
import IntelligentControlSwitch from "./intelligent-control-switch/IntelligentControlSwitch";
import ControlStatusChart from "./control-status-chart/ControlStatusChart";
import { StyledFormControls } from "./StyledFormControls";
import ControlPhysicalDeviceDetailsSkeleton from "./control-pysical-device-details/ControlPhysicalDeviceDetailsSkeleton";
import IntelligentControlSwitchSkeleton from "./intelligent-control-switch/IntelligentControlSwitchSkeleton";
import ControlStatusChartSkeleton from "./control-status-chart/ControlStatusChartSkeleton";
import FeederScheduleOverview from "./feeder-schedule-overview/FeederScheduleOverview";
import { useAppSelector } from "../../store/hooks";

interface FormControlsProps {
  controlType: "Aerators" | "Feeders";
  devices: [...any];
}

const FormControls: FC<FormControlsProps> = ({ controlType, devices }) => {
  const { accessiblePermissions } = useAppSelector(state => state.rolePermissions);
  const intelligentControlList = devices;
  const intelligentControlLoaderList = [{}, {}, {}, {}];
  const [activeControlIndex, setActiveControlIndex] = useState<number>(0);
  const [isDeviceDetailsLoading, setIsDeviceDetailsLoading] = useState<boolean>(true);
  const [isDeviceControlLoading, setIsDeviceControlLoading] = useState<boolean>(true);

  useEffect(() => {
    const handleWheelScroll = (event: WheelEvent) => {
      event.preventDefault();
      const container = document.querySelector(".grid-intelligent-switch-wrap");
      if (container instanceof HTMLElement) {
        container.scrollLeft += event.deltaY > 0 ? 280 : -280;
      }
    };
    const switchGridContainer: any = document.querySelector(".grid-intelligent-switch-wrap");
    if (switchGridContainer) {
      switchGridContainer.addEventListener("wheel", handleWheelScroll, {
        passive: false,
      });
    }

    return () => {
      if (switchGridContainer) {
        switchGridContainer.removeEventListener("wheel", handleWheelScroll);
      }
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsDeviceDetailsLoading(false);
      setIsDeviceControlLoading(false);
    }, 500);
  })

  return (
    <StyledFormControls container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="grid-intelligent-switch-wrap">
        {
          (!isDeviceControlLoading && intelligentControlList?.length) ? intelligentControlList.map((ele, index) => {
            return (
              <IntelligentControlSwitch key={index + 'key'} indexValue={index}
                controlType={controlType} activeControlIndex={activeControlIndex}
                handleControlClick={(index: number) => { setActiveControlIndex(index) }}
                device={ele}
              />
            )
          }) : (intelligentControlLoaderList.map((ele, index) => {
            return (
              <IntelligentControlSwitchSkeleton key={index + 'key'} indexValue={index}
                controlType={controlType} activeControlIndex={activeControlIndex} />
            )
          }))
        }
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginBottom: '10px' }}
          {...(accessiblePermissions.mqtt_historical_data_view && { lg: 3.5, xl: 3.5, md: 3.5 })}>
        {isDeviceDetailsLoading ? <ControlPhysicalDeviceDetailsSkeleton controlType={controlType} /> : <ControlPhysicalDeviceDetails controlType={controlType} activeControlIndex={activeControlIndex} />}
      </Grid>

      {accessiblePermissions.mqtt_historical_data_view ?
        <Grid item xl={8.5} lg={8.5} md={8.5} sm={12} xs={12} sx={{ marginBottom: '10px' }}>
          {isDeviceDetailsLoading ? <ControlStatusChartSkeleton /> : <ControlStatusChart device={intelligentControlList[activeControlIndex]} />}
        </Grid> : <></>}

      {accessiblePermissions.mqtt_devices_timer_view ? <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ height: '100%' }}>
        <FeederScheduleOverview activeControlIndex={activeControlIndex} device={intelligentControlList[activeControlIndex]} />
      </Grid> : <></>}

      {/*<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>*/}
      {/*  <SwitchControlLogs controlType={controlType} activeControlIndex={activeControlIndex} />*/}
      {/*</Grid>*/}
    </StyledFormControls>
  )
}

export default FormControls;