import { Box, Button, FormHelperText, Grid, IconButton, InputAdornment, Link, SvgIcon, TextField, Typography } from '@mui/material';
import agrimorLogoLightImage from '../../assets/icons/agrimor-logo-light.svg';
import agrimorLogoDarkImage from '../../assets/icons/agrimor-logo-dark.svg';
import {
    CheckCircleRounded, ErrorRounded, Visibility, VisibilityOff
} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { WINDOW_INNER_WIDTH } from "../../constants/themes.constants";
import { Formik } from 'formik';
import * as Yup from 'yup';
import useIsMountedRef from "../../hooks/useIsMountedRef";
import type { FC } from 'react';
import { LoginPageContainerStyled } from "./LoginPageStyles";
import { login, userAuthorization } from "../../services/api-service/authApi";
import { ILoginFormPayload } from "../../interfaces/auth.interface";
import { setLocalStorageValue } from "../../services/local-storage-service/localStorage";
import { CLocalStorageItems, ENotificationAlertTypes } from "../../constants/service.contants";
import { useAppDispatch } from '../../store/hooks';
import { saveUserDetails } from '../../store/slices/userSlice';
// import { TOAST_TYPES, showToast } from '../../store/slices/toastSlice';
import { setToastMessages } from '../../store/slices/notificationSlice';
import { CColorCodeVariables } from '../../constants/color.constants';
import { setRolePermissions } from '../../store/slices/rolePermissionSlice';

interface LoginPageProps {
}

const LoginPage: FC<LoginPageProps> = ({ ...rest }) => {
    const navigate = useNavigate();
    const isMountedRef = useIsMountedRef();
    const dispatch = useAppDispatch();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // const triggerNotification = (type: string = '') => {
    //     let messageContent: string = 'Dissolved Oxygen value falls below optimal range at 2.8 mg/L for Intake 1.';

    //     if (type === 'Warning') {
    //         dispatch(setNotificationMessages({
    //             alertType: ENotificationAlertTypes.warning,
    //             title: 'Reading Deviations Alerts',
    //             dateTimeInFormat: 'Today, 3.52pm',
    //             messageContent: messageContent,
    //         }));
    //     } else {
    //         dispatch(setToastMessages({
    //             alertType: ENotificationAlertTypes.error,
    //             title: 'Reading Deviations Alerts',
    //             dateTimeInFormat: 'Today, 3.52pm',
    //             messageContent: messageContent,
    //             timeout: 3000
    //         }));
    //     }
    // }

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        let payload: ILoginFormPayload = {
                            email: values.email || '',
                            password: values.password || '',
                        }

                        const apiResponse: any = await login(payload);

                        if (apiResponse.status) {
                            const accessToken: string = apiResponse['response']['accessToken'] || '';
                            setLocalStorageValue(CLocalStorageItems.accessToken, accessToken);
                            setLocalStorageValue(CLocalStorageItems.sessionLogin, apiResponse?.response?.sessionLogin);

                            const userDetails = await userAuthorization();
                            if (userDetails?.status) {
                                const userData: any = userDetails?.response?.data;
                                if (userData?.role?.length && userData?.role[0]?.permissions?.length) dispatch(setRolePermissions(userData?.role[0]?.permissions));
                                dispatch(saveUserDetails(userData));
                                navigate('/')
                            }
                        } else {
                            // dispatch(showToast({
                            //     type: TOAST_TYPES?.ERROR,
                            //     message: apiResponse?.response?.message || "Invalid email or password"
                            // }));                            

                            dispatch(setToastMessages({
                                alertType: ENotificationAlertTypes.error,
                                title: 'Login Failed',
                                dateTimeInFormat: 'Today, 3.52pm',
                                messageContent: "Your email or password is incorrect, Please try again!...", // apiResponse?.response?.message
                                timeout: 3000
                            }));
                        }
                        if (isMountedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err: any) {
                        if (isMountedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }} >
                {({ errors, isSubmitting, touched, values, isValid, dirty,
                    handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit} {...rest} >
                        <LoginPageContainerStyled container ismobile={isMobile.toString()}>
                            {!isMobile ?
                                <Grid item className="left-grid-item">
                                    <img src={agrimorLogoLightImage}
                                        className="agrimor-logo-light"
                                        alt="Agrimor" />
                                </Grid> : <></>}

                            <Grid item className="right-grid-item">
                                <Box className="box-wrap">
                                    <Grid container className="grid-container">
                                        {isMobile ? <Grid item mb={4} xl={12} lg={12} md={12} sm={12} xs={12} className="agrimor-logo-wrap">
                                            <img src={agrimorLogoDarkImage}
                                                className="agrimor-logo-dark"
                                                alt="Agrimor" />
                                        </Grid> : <></>}

                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Typography variant="h3" className="title">
                                                Login
                                            </Typography>
                                        </Grid>

                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={2} >
                                            <Typography className="label"> Email Address </Typography>

                                            <TextField type="email" fullWidth className="field" placeholder="Email" name="email" variant="outlined"
                                                error={Boolean(touched.email && errors.email)} autoComplete='off' value={values.email}
                                                onBlur={handleBlur} onChange={handleChange}
                                                InputProps={touched.email ? {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <SvgIcon fontSize="small" sx={{ color: Boolean(errors.email) ? 'red' : 'green' }}>
                                                                {Boolean(errors.email) ? <ErrorRounded /> : <CheckCircleRounded />}
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    )
                                                } : {}}
                                            />
                                            <FormHelperText error>
                                                {touched.email && errors.email}
                                            </FormHelperText>
                                        </Grid>

                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={1} >
                                            <Typography className="label"> Password </Typography>
                                            <TextField type={showPassword ? 'text' : 'password'} fullWidth
                                                className="field" placeholder="Password" name="password" variant="outlined"
                                                error={Boolean(touched.password && errors.password)} autoComplete="new-password"
                                                value={values.password} onBlur={handleBlur} onChange={handleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="Toggle password visibility"
                                                                onClick={() => { setShowPassword(!showPassword) }}
                                                                sx={{ height: '24px', width: '24px' }}>
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                            <FormHelperText error>
                                                {touched.password && errors.password}
                                            </FormHelperText>
                                        </Grid>

                                        <Grid item mb={3} xl={12} lg={12} md={12} sm={12} xs={12} >
                                            <Typography sx={{ float: 'right' }}>
                                                <Link
                                                    onClick={() => navigate('/forgot-password')}
                                                    underline="none"
                                                    sx={{ color: '#6a6868', cursor: 'pointer', fontWeight: 400 }}>
                                                    Forgot Password
                                                </Link>
                                            </Typography>
                                        </Grid>

                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={3}>
                                            {errors.submit && (
                                                <Box mt={3}>
                                                    <FormHelperText error>
                                                        {errors.submit}
                                                    </FormHelperText>
                                                </Box>
                                            )}

                                            <Typography>
                                                <Button type="submit" variant="contained" className="submit-btn" disabled={!isValid || !dirty}>LOGIN</Button>
                                            </Typography>
                                        </Grid>

                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={2}>
                                            <Typography className="register-wrap"> Don't have an account? <Button
                                                sx={{ fontWeight: 600, color: `rgba(${CColorCodeVariables.turquoiseOasis}, 1)` }} onClick={() => navigate('/signup')}>Register</Button></Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </LoginPageContainerStyled>
                    </form>
                )}
            </Formik>
        </>
    );
}

export default LoginPage