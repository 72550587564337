export enum USER_ROLE_PERMISSION {
    MANAGE_FARMS_VIEW = 'MANAGE_FARMS_VIEW',
    MANAGE_FARMS_ADD = 'MANAGE_FARMS_ADD',
    MANAGE_FARMS_EDIT = 'MANAGE_FARMS_EDIT',
    MANAGE_FARMS_DELETE = 'MANAGE_FARMS_DELETE',
    MANAGE_HIERARCHY_STRUCTURE_VIEW = 'MANAGE_HIERARCHY_STRUCTURE_VIEW',
    MANAGE_HIERARCHY_STRUCTURE_ADD = 'MANAGE_HIERARCHY_STRUCTURE_ADD',
    MANAGE_HIERARCHY_STRUCTURE_EDIT = 'MANAGE_HIERARCHY_STRUCTURE_EDIT',
    MANAGE_HIERARCHY_STRUCTURE_DELETE = 'MANAGE_HIERARCHY_STRUCTURE_DELETE',
    MANAGE_USERS_VIEW = 'MANAGE_USERS_VIEW',
    MANAGE_USERS_ADD = 'MANAGE_USERS_ADD',
    MANAGE_USERS_EDIT = 'MANAGE_USERS_EDIT',
    MANAGE_USERS_DELETE = 'MANAGE_USERS_DELETE',
    MANAGE_ROLES_VIEW = 'MANAGE_ROLES_VIEW',
    MANAGE_ROLES_ADD = 'MANAGE_ROLES_ADD',
    MANAGE_ROLES_EDIT = 'MANAGE_ROLES_EDIT',
    MANAGE_ROLES_DELETE = 'MANAGE_ROLES_DELETE',
    MANAGE_SPECIES_TYPE_VIEW = 'MANAGE_SPECIES_TYPE_VIEW',
    MANAGE_SPECIES_TYPE_ADD = 'MANAGE_SPECIES_TYPE_ADD',
    MANAGE_SPECIES_TYPE_EDIT = 'MANAGE_SPECIES_TYPE_EDIT',
    MANAGE_SPECIES_TYPE_DELETE = 'MANAGE_SPECIES_TYPE_DELETE',
    MANAGE_SPECIES_DETAILS_VIEW = 'MANAGE_SPECIES_DETAILS_VIEW',
    MANAGE_SPECIES_DETAILS_ADD = 'MANAGE_SPECIES_DETAILS_ADD',
    MANAGE_SPECIES_DETAILS_EDIT = 'MANAGE_SPECIES_DETAILS_EDIT',
    MANAGE_SPECIES_DETAILS_DELETE = 'MANAGE_SPECIES_DETAILS_DELETE',
    MANAGE_MQTT_HISTORICAL_DATA_VIEW = 'MANAGE_MQTT_HISTORICAL_DATA_VIEW',
    MANAGE_MQTT_SENSOR_HISTORICAL_DATA_VIEW = 'MANAGE_MQTT_SENSOR_HISTORICAL_DATA_VIEW',
    MANAGE_MQTT_LATEST_OVERALL_WATER_QUALITY_DATA_VIEW = 'MANAGE_MQTT_LATEST_OVERALL_WATER_QUALITY_DATA_VIEW',
    MANAGE_MQTT_DEVICES_VIEW = 'MANAGE_MQTT_DEVICES_VIEW',
    MANAGE_MQTT_DEVICES_ADD = 'MANAGE_MQTT_DEVICES_ADD',
    MANAGE_MQTT_DEVICES_EDIT = 'MANAGE_MQTT_DEVICES_EDIT',
    MANAGE_MQTT_DEVICES_DELETE = 'MANAGE_MQTT_DEVICES_DELETE',
    MANAGE_MQTT_DEVICES_SEND_COMMANDS = 'MANAGE_MQTT_DEVICES_SEND_COMMANDS',
    MANAGE_MQTT_DEVICES_TIMER_VIEW = 'MANAGE_MQTT_DEVICES_TIMER_VIEW',
    MANAGE_MQTT_DEVICES_TIMER_ADD = 'MANAGE_MQTT_DEVICES_TIMER_ADD',
    MANAGE_MQTT_DEVICES_TIMER_EDIT = 'MANAGE_MQTT_DEVICES_TIMER_EDIT',
    MANAGE_MQTT_DEVICES_TIMER_DELETE = 'MANAGE_MQTT_DEVICES_TIMER_DELETE',
    MANAGE_MQTT_CCTV_VIEW = 'MANAGE_MQTT_CCTV_VIEW',
    MANAGE_MQTT_FEEDERS_VIEW = 'MANAGE_MQTT_FEEDERS_VIEW',
    MANAGE_MQTT_FEEDERS_ADD = 'MANAGE_MQTT_FEEDERS_ADD',
    MANAGE_MQTT_FEEDERS_EDIT = 'MANAGE_MQTT_FEEDERS_EDIT',
    MANAGE_MQTT_FEEDERS_DELETE = 'MANAGE_MQTT_FEEDERS_DELETE',
    MANAGE_MQTT_AERATORS_VIEW = 'MANAGE_MQTT_AERATORS_VIEW',
    MANAGE_MQTT_AERATORS_ADD = 'MANAGE_MQTT_AERATORS_ADD',
    MANAGE_MQTT_AERATORS_EDIT = 'MANAGE_MQTT_AERATORS_EDIT',
    MANAGE_MQTT_AERATORS_DELETE = 'MANAGE_MQTT_AERATORS_DELETE',
}
