import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USER_ROLE_PERMISSION } from '../../constants/permission.constants';

interface accessiblePermissionProps {
    "farms_view": boolean;
    "farms_add": boolean;
    "farms_edit": boolean;
    "farms_delete": boolean;
    "hierarchy_structure_view": boolean;
    "hierarchy_structure_add": boolean;
    "hierarchy_structure_edit": boolean;
    "hierarchy_structure_delete": boolean;
    "users_view": boolean;
    "users_add": boolean;
    "users_edit": boolean;
    "users_delete": boolean;
    "roles_view": boolean;
    "roles_add": boolean;
    "roles_edit": boolean;
    "roles_delete": boolean;
    "species_type_view": boolean;
    "species_type_add": boolean;
    "species_type_edit": boolean;
    "species_type_delete": boolean;
    "species_details_view": boolean;
    "species_details_add": boolean;
    "species_details_edit": boolean;
    "species_details_delete": boolean;
    "mqtt_historical_data_view": boolean;
    "mqtt_sensor_historical_data_view": boolean;
    "mqtt_latest_overall_water_quality_data_view": boolean;
    "mqtt_devices_view": boolean;
    "mqtt_devices_add": boolean;
    "mqtt_devices_edit": boolean;
    "mqtt_devices_delete": boolean;
    "mqtt_devices_send_commands": boolean;
    "mqtt_devices_timer_view": boolean;
    "mqtt_devices_timer_add": boolean;
    "mqtt_devices_timer_edit": boolean;
    "mqtt_devices_timer_delete": boolean;
    "mqtt_cctv_view": boolean;
    "manage_mqtt_feeders_view": boolean;
    "manage_mqtt_feeders_add": boolean;
    "manage_mqtt_feeders_edit": boolean;
    "manage_mqtt_feeders_delete": boolean;
    "manage_mqtt_aerators_view": boolean;
    "manage_mqtt_aerators_add": boolean;
    "manage_mqtt_aerators_edit": boolean;
    "manage_mqtt_aerators_delete": boolean;
}

interface rolePermissionStateProps {
    dbPermissions: string[],
    appPermissions: {},
    accessiblePermissions: accessiblePermissionProps
}

const initialState: rolePermissionStateProps = {
    dbPermissions: [],
    appPermissions: [],
    accessiblePermissions: {
        "farms_view": false,
        "farms_add": false,
        "farms_edit": false,
        "farms_delete": false,
        "hierarchy_structure_view": false,
        "hierarchy_structure_add": false,
        "hierarchy_structure_edit": false,
        "hierarchy_structure_delete": false,
        "users_view": false,
        "users_add": false,
        "users_edit": false,
        "users_delete": false,
        "roles_view": false,
        "roles_add": false,
        "roles_edit": false,
        "roles_delete": false,
        "species_type_view": false,
        "species_type_add": false,
        "species_type_edit": false,
        "species_type_delete": false,
        "species_details_view": false,
        "species_details_add": false,
        "species_details_edit": false,
        "species_details_delete": false,
        "mqtt_historical_data_view": false,
        "mqtt_sensor_historical_data_view": false,
        "mqtt_latest_overall_water_quality_data_view": false,
        "mqtt_devices_view": false,
        "mqtt_devices_add": false,
        "mqtt_devices_edit": false,
        "mqtt_devices_delete": false,
        "mqtt_devices_send_commands": false,
        "mqtt_devices_timer_view": false,
        "mqtt_devices_timer_add": false,
        "mqtt_devices_timer_edit": false,
        "mqtt_devices_timer_delete": false,
        "mqtt_cctv_view": false,
        "manage_mqtt_feeders_view": false,
        "manage_mqtt_feeders_add": false,
        "manage_mqtt_feeders_edit": false,
        "manage_mqtt_feeders_delete": false,
        "manage_mqtt_aerators_view": false,
        "manage_mqtt_aerators_add": false,
        "manage_mqtt_aerators_edit": false,
        "manage_mqtt_aerators_delete": false,
    }
}

const rolePermissionSlice = createSlice({
    name: 'rolePermissions',
    initialState,
    reducers: {
        setRolePermissions: (state, action: PayloadAction<{ permissions: string[] }>) => {
            state.dbPermissions = action.payload.permissions;
            state.appPermissions = USER_ROLE_PERMISSION;
            state.accessiblePermissions = {
                "farms_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_FARMS_VIEW),
                "farms_add": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_FARMS_ADD),
                "farms_edit": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_FARMS_EDIT),
                "farms_delete": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_FARMS_DELETE),
                "hierarchy_structure_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_HIERARCHY_STRUCTURE_VIEW),
                "hierarchy_structure_add": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_HIERARCHY_STRUCTURE_ADD),
                "hierarchy_structure_edit": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_HIERARCHY_STRUCTURE_EDIT),
                "hierarchy_structure_delete": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_HIERARCHY_STRUCTURE_DELETE),
                "users_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_USERS_VIEW),
                "users_add": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_USERS_ADD),
                "users_edit": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_USERS_EDIT),
                "users_delete": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_USERS_DELETE),
                "roles_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_ROLES_VIEW),
                "roles_add": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_ROLES_ADD),
                "roles_edit": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_ROLES_EDIT),
                "roles_delete": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_ROLES_DELETE),
                "species_type_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_SPECIES_TYPE_VIEW),
                "species_type_add": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_SPECIES_TYPE_ADD),
                "species_type_edit": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_SPECIES_TYPE_EDIT),
                "species_type_delete": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_SPECIES_TYPE_DELETE),
                "species_details_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_SPECIES_DETAILS_VIEW),
                "species_details_add": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_SPECIES_DETAILS_ADD),
                "species_details_edit": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_SPECIES_DETAILS_EDIT),
                "species_details_delete": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_SPECIES_DETAILS_DELETE),
                "mqtt_historical_data_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_HISTORICAL_DATA_VIEW),
                "mqtt_sensor_historical_data_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_SENSOR_HISTORICAL_DATA_VIEW),
                "mqtt_latest_overall_water_quality_data_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_LATEST_OVERALL_WATER_QUALITY_DATA_VIEW),
                "mqtt_devices_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_DEVICES_VIEW),
                "mqtt_devices_add": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_DEVICES_ADD),
                "mqtt_devices_edit": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_DEVICES_EDIT),
                "mqtt_devices_delete": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_DEVICES_DELETE),
                "mqtt_devices_send_commands": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_DEVICES_SEND_COMMANDS),
                "mqtt_devices_timer_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_DEVICES_TIMER_VIEW),
                "mqtt_devices_timer_add": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_DEVICES_TIMER_ADD),
                "mqtt_devices_timer_edit": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_DEVICES_TIMER_EDIT),
                "mqtt_devices_timer_delete": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_DEVICES_TIMER_DELETE),
                "mqtt_cctv_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_CCTV_VIEW),
                "manage_mqtt_feeders_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_FEEDERS_VIEW),
                "manage_mqtt_feeders_add": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_FEEDERS_ADD),
                "manage_mqtt_feeders_edit": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_FEEDERS_EDIT),
                "manage_mqtt_feeders_delete": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_FEEDERS_DELETE),
                "manage_mqtt_aerators_view": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_AERATORS_VIEW),
                "manage_mqtt_aerators_add": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_AERATORS_ADD),
                "manage_mqtt_aerators_edit": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_AERATORS_EDIT),
                "manage_mqtt_aerators_delete": action.payload.permissions.includes(USER_ROLE_PERMISSION.MANAGE_MQTT_AERATORS_DELETE),
            }
        },
    }
});

export const setRolePermissions = (permissions: string[] = []) => (dispatch: any) => {
    dispatch(rolePermissionSlice.actions.setRolePermissions({ permissions }));
}

export const reducer = rolePermissionSlice.reducer;

export default rolePermissionSlice.reducer;