import { Outlet } from "react-router-dom"
import InjectAxiosInterceptors from "../services/api-service/injectAxiosInterceptors"
import { useEffect } from "react"
import { userAuthorization } from "../services/api-service/authApi"
import { useAppDispatch } from "../store/hooks"
import { saveUserDetails } from "../store/slices/userSlice"
import { setRolePermissions } from "../store/slices/rolePermissionSlice"

const Root = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        getUserAuth()
    })

    const getUserAuth = async () => {
        const apiResponse = await userAuthorization();
        if (apiResponse?.status) {
            const userData: any = apiResponse?.response?.data;
            if (userData?.role?.length && userData?.role[0]?.permissions?.length) dispatch(setRolePermissions(userData?.role[0]?.permissions));
            dispatch(saveUserDetails(userData));
        }
    }

    return <>
        <InjectAxiosInterceptors />
        <Outlet />
    </>
}
export default Root