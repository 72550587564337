import { FC, useEffect, useMemo, useState } from "react";
import { StyledFeederScheduleForm } from "./StyledFeederScheduleForm";
import { Button, Grid } from "@mui/material";
import TimePicker from "../../../../common/time-picker/TimePicker";
import ConfigSwitch from "../../../../common/config-switch/ConfigSwitch";
import {
  IScheduleForm,
  TypeScheduleControlKey,
} from "../../../../interfaces/feeder-schedule-interface";
import {
  DAYS,
  WEEKDAY_LIST,
} from "../../../../constants/feeder-schedule.constants";
import * as _ from 'lodash';
import { useAppSelector } from "../../../../store/hooks";

const TIME_KEYS = {
  START_TIME: "startTime",
  END_TIME: "endTime",
};

interface FeederScheduleFormProps {
  adding: boolean;
  zeroSchedules: boolean;
  handleScheduleAddToList: (schedule: IScheduleForm) => void;
  schedule: IScheduleForm;
}

const FeederScheduleForm: FC<FeederScheduleFormProps> = ({
  adding,
  zeroSchedules,
  handleScheduleAddToList,
  schedule
}) => {
  const { accessiblePermissions } = useAppSelector(state => state.rolePermissions);
  const [scheduleForm, setScheduleForm] = useState<IScheduleForm>(schedule);
  const [isScheduleEqual, setIsScheduleEqual] = useState<boolean>(true);

  useEffect(() => {
    setScheduleForm(schedule);
  }, [schedule]);

  useEffect(() => {
    setIsScheduleEqual(_.isEqual(schedule, scheduleForm));
    // eslint-disable-next-line
  }, [scheduleForm]);

  const resetForm = () => {
    setScheduleForm(schedule);
  }

  const saveForm = () => {
    scheduleForm.frequencies = scheduleFrequency;
    handleScheduleAddToList(scheduleForm);
    setIsScheduleEqual(true);
  }

  const handleSwitchChange = (key: TypeScheduleControlKey) => {
    if (key === "daily") {
      setScheduleForm((prev) => {
        const daysValue: { [key: string]: boolean } = {};
        DAYS.forEach((day: string) => {
          daysValue[day] = !prev?.daily;
        });
        return { ...prev, daily: !prev?.daily, ...daysValue };
      });
    } else {
      setScheduleForm((prev) => {
        const freqDays = DAYS?.filter((day) => !prev[day]);
        let daily = prev?.daily;
        if (!freqDays?.length) {
          daily = false;
        } else if (freqDays?.length === 1 && freqDays[0] === key) {
          daily = true;
        }
        return { ...prev, [key]: !prev[key], daily };
      });
    }
  };

  const handleTimeChange = (time: string, key: string) => {
    setScheduleForm((prev) => ({ ...prev, [key]: time }));
  };

  const scheduleFrequency = useMemo(() => {
    return DAYS?.filter((day) => scheduleForm[day]).length;
  }, [scheduleForm]);

  return (
    <StyledFeederScheduleForm>
      {zeroSchedules ? (
        <div className="no-schedule-wrap">
          <div>
            <p className="text">
              You have no schedules, please select one schedule to add.{" "}
            </p>
            <p className="text">
              You can only have up to maximum of 8 schedules.
            </p>
          </div>
        </div>
      ) : (
        <Grid container>
          <Grid item lg={4} xl={4}>
            <div className="sc-form-control">
              <p className="sc-label">Start Time</p>
              <TimePicker disable={!accessiblePermissions.mqtt_devices_timer_edit}
                value={scheduleForm.startTime}
                handleTimeChange={(time: string) =>
                  handleTimeChange(time, TIME_KEYS.START_TIME)
                }
              />
            </div>
            <div className="sc-form-control">
              <p className="sc-label">End Time</p>
              <TimePicker disable={!accessiblePermissions.mqtt_devices_timer_edit}
                value={scheduleForm.endTime}
                handleTimeChange={(time: string) =>
                  handleTimeChange(time, TIME_KEYS.END_TIME)
                }
              />
            </div>
            <div className="sc-form-control">
              <p className="sc-label">Frequencies</p>
              <input 
                className="sc-input sc-day"
                type="number"
                value={scheduleFrequency}
                readOnly
              />
            </div>
            <div className="sc-form-control">
              <p className="sc-label">Daily</p>
              <ConfigSwitch disabled={!accessiblePermissions.mqtt_devices_timer_edit}
                handleSwitchChange={() => handleSwitchChange("daily")}
                conformationModal={false}
                size="lg"
                name={"daily"}
                checked={scheduleForm.daily}
              />
            </div>
          </Grid>
          <Grid item lg={4} xl={4}>
            {WEEKDAY_LIST?.map((week) => (
              <div key={week?.id} className="sc-form-control">
                <p className="sc-label">{week?.label}</p>
                <ConfigSwitch disabled={!accessiblePermissions.mqtt_devices_timer_edit}
                  handleSwitchChange={() => handleSwitchChange(week?.key)}
                  conformationModal={false}
                  size="lg"
                  name={week?.key}
                  checked={scheduleForm[week?.key]}
                />
              </div>
            ))}
          </Grid>
          <Grid item lg={4} xl={4}>
            <div className="sc-form-control">
              <p className="sc-label">Saturday</p>
              <ConfigSwitch disabled={!accessiblePermissions.mqtt_devices_timer_edit}
                handleSwitchChange={() => handleSwitchChange("saturday")}
                conformationModal={false}
                size="lg"
                name={"saturday"}
                checked={scheduleForm.saturday}
              />
            </div>
            <div className="sc-form-control">
              <p className="sc-label">Sunday</p>
              <ConfigSwitch disabled={!accessiblePermissions.mqtt_devices_timer_edit}
                handleSwitchChange={() => handleSwitchChange("sunday")}
                conformationModal={false}
                size="lg"
                name={"sunday"}
                checked={scheduleForm.sunday}
              />
            </div>
            <Button
              style={{
                color: "rgba(100, 116, 139, 1)",
                background: "rgba(255, 255, 255, 0.4)",
              }}
              className="sc-btn"
              onClick={() => resetForm()}
            >
              RESTORE
            </Button>
            {accessiblePermissions.mqtt_devices_timer_add ? <Button className="sc-btn" disabled={isScheduleEqual} onClick={() => saveForm()}>SAVE</Button> : <></>}
          </Grid>
        </Grid>
      )}
    </StyledFeederScheduleForm>
  );
};

export default FeederScheduleForm;
